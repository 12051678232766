<template>
  <div ref="templateRef" :class="templateStyle">
    <div ref="lightRef" :class="lightStyle" />
    <div ref="contentWrapper" class="content-wrapper">
      <slot />
      <canvas ref="canvasRef" width="50" :class="canvasStyle" />
    </div>
  </div>
</template>

<script>

import {computed, onMounted, onUnmounted, ref} from "vue";
import {MENU_POSITION} from "./data";

export default {
  name: "Lamp",
  props: ['position'],
  setup(props) {
    const canvasRef = ref();
    const templateRef = ref();
    const contentWrapperRef = ref();
    const lightRef = ref();

    const canvasSize = 50;
    const lightHomeShift = "-100px";
    const hasCursorInside = ref(false);

    const lightStyle = computed(() => {
      const {position} = props;

      return {
        light: true,
        'light-left': position === MENU_POSITION.LEFT,
        'light-right': position === MENU_POSITION.RIGHT,
        'light-top': position === MENU_POSITION.TOP,
        'light-bottom': position === MENU_POSITION.BOTTOM,
        'light-left-animation': position === MENU_POSITION.LEFT && hasCursorInside.value,
        'light-right-animation': position === MENU_POSITION.RIGHT && hasCursorInside.value,
        'light-top-animation': position === MENU_POSITION.TOP && hasCursorInside.value,
        'light-bottom-animation': position === MENU_POSITION.BOTTOM && hasCursorInside.value
      }
    })

    const templateStyle = computed(() => ({
      'template-lamp': true,
      'template-lamp-right': props.position === MENU_POSITION.RIGHT
    }))

    const canvasStyle = computed(() => {
      const {position} = props;
      return {
        'canvas': true,
        'canvas-left': position === MENU_POSITION.LEFT,
        'canvas-right': position === MENU_POSITION.RIGHT,
        'canvas-top': position === MENU_POSITION.TOP,
        'canvas-bottom': position === MENU_POSITION.BOTTOM,
      }
    })

    const lampVariant = {
      [MENU_POSITION.TOP]: {
        canvas: {
          getWidth: () => contentWrapperRef.value.getBoundingClientRect().width,
          getHeight: () => canvasSize,
          p0X: () => 0,
          p0Y: () => 0,
          cp1X: () => contentWrapperRef.value.getBoundingClientRect().width * (1 / 4),
          cp1Y: () => canvasSize,
          cp2X: () => contentWrapperRef.value.getBoundingClientRect().width * (3 / 4),
          cp2Y: () => canvasSize,
          p1X: () => contentWrapperRef.value.getBoundingClientRect().width,
          p1Y: () => 0
        },
        light: {
          defaultPosition: {
            top: lightHomeShift,
            bottom: 'unset',
            left: '50%',
            right: 'unset'
          },
          a: () => contentWrapperRef.value.getBoundingClientRect().width / 2,
          b: () => canvasSize,
          x: (mousePosition) => mousePosition.clientX - canvasRef.value.getBoundingClientRect().left - canvasRef.value.getBoundingClientRect().width / 2,
          top: (_, y) => `${y + contentWrapperRef.value.getBoundingClientRect().height - canvasRef.value.getBoundingClientRect().height}px`,
          bottom: () => 'unset',
          left: (x) => `${x + canvasRef.value.getBoundingClientRect().width / 2}px`,
          right: () => 'unset',
        }
      },
      [MENU_POSITION.BOTTOM]: {
        canvas: {
          getWidth: () => contentWrapperRef.value.getBoundingClientRect().width,
          getHeight: () => canvasSize,
          p0X: () => 0,
          p0Y: () => canvasSize,
          cp1X: () => contentWrapperRef.value.getBoundingClientRect().width * (1 / 4),
          cp1Y: () => 0,
          cp2X: () => contentWrapperRef.value.getBoundingClientRect().width * (3 / 4),
          cp2Y: () => 0,
          p1X: () => contentWrapperRef.value.getBoundingClientRect().width,
          p1Y: () => canvasSize
        },
        light: {
          defaultPosition: {
            top: 'unset',
            bottom: lightHomeShift,
            left: '50%',
            right: 'unset'
          },
          a: () => contentWrapperRef.value.getBoundingClientRect().width / 2,
          b: () => canvasSize,
          x: (mousePosition) => mousePosition.clientX - canvasRef.value.getBoundingClientRect().left - canvasRef.value.getBoundingClientRect().width / 2,
          top: () => 'unset',
          bottom: (_, y) => `${y + contentWrapperRef.value.getBoundingClientRect().height - canvasRef.value.getBoundingClientRect().height}px`,
          left: (x) => `${x + canvasRef.value.getBoundingClientRect().width / 2}px`,
          right: () => 'unset',
        }
      },
      [MENU_POSITION.LEFT]: {
        canvas: {
          getWidth: () => canvasSize,
          getHeight: () => contentWrapperRef.value.getBoundingClientRect().height,
          p0X: () => 0,
          p0Y: () => 0,
          cp1X: () => canvasSize,
          cp1Y: () => contentWrapperRef.value.getBoundingClientRect().height * (1 / 4),
          cp2X: () => canvasSize,
          cp2Y: () => contentWrapperRef.value.getBoundingClientRect().height * (3 / 4),
          p1X: () => 0,
          p1Y: () => contentWrapperRef.value.getBoundingClientRect().height
        },
        light: {
          defaultPosition: {
            top: '50%',
            bottom: 'unset',
            left: lightHomeShift,
            right: 'unset'
          },
          a: () => contentWrapperRef.value.getBoundingClientRect().height / 2,
          b: () => canvasSize,
          x: (mousePosition) => mousePosition.clientY - canvasRef.value.getBoundingClientRect().top - canvasRef.value.getBoundingClientRect().height / 2,
          top: (x) => `${x + canvasRef.value.getBoundingClientRect().height / 2}px`,
          bottom: () => 'unset',
          left: (_, y) => `${y + canvasRef.value.getBoundingClientRect().left}px`,
          right: () => 'unset',
        }
      },
      [MENU_POSITION.RIGHT]: {
        canvas: {
          getWidth: () => canvasSize,
          getHeight: () => contentWrapperRef.value.getBoundingClientRect().height,
          p0X: () => canvasSize,
          p0Y: () => 0,
          cp1X: () => 0,
          cp1Y: () => contentWrapperRef.value.getBoundingClientRect().height * (1 / 4),
          cp2X: () => 0,
          cp2Y: () => contentWrapperRef.value.getBoundingClientRect().height * (3 / 4),
          p1X: () => canvasSize,
          p1Y: () => contentWrapperRef.value.getBoundingClientRect().height
        },
        light: {
          defaultPosition: {
            top: '50%',
            bottom: 'unset',
            left: 'unset',
            right: lightHomeShift
          },
          a: () => contentWrapperRef.value.getBoundingClientRect().height / 2,
          b: () => canvasSize,
          x: (mousePosition) => mousePosition.clientY - canvasRef.value.getBoundingClientRect().top - canvasRef.value.getBoundingClientRect().height / 2,
          top: (x) => `${x + canvasRef.value.getBoundingClientRect().height / 2}px`,
          bottom: () => 'unset',
          left: () => 'unset',
          right: (_, right) => `${contentWrapperRef.value.getBoundingClientRect().width / 2 + right}px`,
        }
      }
    }


    const drawSeparator = () => {
      const {position} = props;
      const ctx = canvasRef.value.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(lampVariant[position].canvas.p0X(), lampVariant[position].canvas.p0Y());
      ctx.bezierCurveTo(
          lampVariant[position].canvas.cp1X(), lampVariant[position].canvas.cp1Y(),
          lampVariant[position].canvas.cp2X(), lampVariant[position].canvas.cp2Y(),
          lampVariant[position].canvas.p1X(), lampVariant[position].canvas.p1Y());
      ctx.strokeStyle = '#2e3e4e';
      ctx.lineWidth = 5;
      ctx.stroke();
    }

    const setCanvasSize = () => {
      const {position} = props;
      canvasRef.value.height = lampVariant[position].canvas.getHeight();
      canvasRef.value.style.height = `${lampVariant[position].canvas.getHeight()}px`;
      canvasRef.value.width = lampVariant[position].canvas.getWidth();
      canvasRef.value.style.width = `${lampVariant[position].canvas.getWidth()}px`;
    }

    const mousePositionUpdated = (mousePosition) => {
      const {position} = props;

      const a = lampVariant[position].light.a()
      const b = lampVariant[position].light.b()
      const x = lampVariant[position].light.x(mousePosition)

      const y = (b * Math.sqrt((a * a) - (x * x))) / a

      lightRef.value.style.top = lampVariant[position].light.top(x, y)
      lightRef.value.style.bottom = lampVariant[position].light.bottom(x, y)
      lightRef.value.style.right = lampVariant[position].light.right(x, y)
      lightRef.value.style.left = lampVariant[position].light.left(x, y)
    }

    const initLight = () => {
      removeAnimation()
      const {position} = props;
      lightRef.value.style.top = lampVariant[position].light.defaultPosition.top
      lightRef.value.style.bottom = lampVariant[position].light.defaultPosition.bottom
      lightRef.value.style.right = lampVariant[position].light.defaultPosition.right
      lightRef.value.style.left = lampVariant[position].light.defaultPosition.left
    }

    const onResize = () => {
      initSeparator();
      initLight();
    }

    const initSeparator = () => {
      setCanvasSize()
      drawSeparator()
    }

    const addAnimation = () => {
      hasCursorInside.value = true;
    }

    const removeAnimation = () => {
      hasCursorInside.value = false;
    }

    onMounted(() => {
      onResize();
      templateRef.value.addEventListener('mousemove', mousePositionUpdated)
      templateRef.value.addEventListener('mouseenter', removeAnimation)
      templateRef.value.addEventListener('mouseleave', addAnimation)
      window.addEventListener('resize', onResize);
    })

    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
      templateRef.value.removeEventListener('mousemove', mousePositionUpdated)
      templateRef.value.removeEventListener('mouseenter', removeAnimation)
      templateRef.value.removeEventListener('mouseleave', addAnimation)
    })

    return {
      canvasRef,
      contentWrapper: contentWrapperRef,
      lightRef,
      lightHomeShift,
      lightStyle,
      templateRef,
      templateStyle,
      canvasSize,
      canvasStyle
    }
  }
}
</script>

<style scoped>
.light {
  height: 0;
  width: 0;
  position: absolute;
  box-shadow: 0 0 60px 10px #fff, 0 0 140px 60px var(--accent-color);
}

.light-left {
  top: 50%;
  left: v-bind(lightHomeShift) px;
  right: unset;
  bottom: unset;
}

.light-right {
  top: 50%;
  right: v-bind(lightHomeShift) px;
  left: unset;
  bottom: unset;
}

.light-bottom {
  left: 50%;
  bottom: v-bind(lightHomeShift) px;
  right: unset;
  top: unset;
}


.light-top {
  left: 50%;
  top: v-bind(lightHomeShift) px;
  bottom: unset;
  right: unset;
}


.template-lamp {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.template-lamp-right {
  flex-direction: row-reverse;
}

.canvas {
  position: absolute;

}

.canvas-left {
  height: 100%;
  width: v-bind(canvasSize) px;
  top: 0;
  right: 0;
  left: unset;
  bottom: unset;
}

.canvas-right {
  height: 100%;
  width: v-bind(canvasSize) px;
  top: 0;
  left: 0;
  bottom: unset;
}

.canvas-bottom {
  height: v-bind(canvasSize) px;
  width: 100%;
  top: 0;
  left: 0;
  bottom: unset;
}

.canvas-top {
  height: v-bind(canvasSize) px;
  width: 100%;
  bottom: 0;
  left: 0;
  top: unset;
}

.content-wrapper {
  position: relative;
}

.light-left-animation {
  animation: light-home-left-animation 2s forwards
}

.light-right-animation {
  animation: light-home-right-animation 2s forwards
}

.light-top-animation {
  animation: light-home-top-animation 2s forwards
}

.light-bottom-animation {
  animation: light-home-bottom-animation 2s forwards
}


@keyframes light-home-top-animation {
  to {
    left: 50%;
    top: v-bind(lightHomeShift);
  }
}

@keyframes light-home-bottom-animation {
  to {
    left: 50%;
    bottom: v-bind(lightHomeShift);
  }
}

@keyframes light-home-left-animation {
  to {
    top: 50%;
    left: v-bind(lightHomeShift);
  }
}

@keyframes light-home-right-animation {
  to {
    top: 50%;
    right: v-bind(lightHomeShift);
  }
}
</style>