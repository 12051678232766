<template>
  <img class="wip" src="@/assets/WIP.svg">
</template>

<script>
export default {
  name: "WIP"
}
</script>

<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  /*20% {*/
  /*  transform: rotate(9deg);*/
  /*}*/
  25% {
    transform: rotate(1deg);
  }
  /*30% {*/
  /*  transform: rotate(9deg);*/
  /*}*/
  50% {
    transform: rotate(0deg);
  }
  /*70%{*/
  /*  transform: rotate(-9deg);*/
  /*}*/
  75% {
    transform: rotate(-1deg);
  }
  /*80%{*/
  /*  transform: rotate(-9deg);*/
  /*}*/
  100% {
    transform: rotate(0deg);
  }
}

.wip {
  height: 210px;
  width: 210px;
  animation: rotation 2s infinite linear;
  transform-origin: top center;
}
</style>