<template>
  <div class="template-name">
    Neto da Silva <span class="firstname">André</span>
  </div>
</template>

<script>
export default {
  name: "Name"
}
</script>

<style scoped>
.template-name{
  color: var(--primary-color-light);
  font-weight: bold;
  font-size: larger;
}

.firstname{
  color: var(--accent-color);
}
</style>
