import {onMounted, ref} from "vue";

const useMobile = () => {
  const isMobile = ref(false);

  const setIsMobile = () => {
    isMobile.value = window.innerWidth < 1200;
  }

  onMounted(() => {
    setIsMobile();
    window.addEventListener('resize', setIsMobile);
  })

  return {
    isMobile
  }
}

export default useMobile;
