<template>
  <ANSBaseInput v-slot="slotProps" :label="label">
    <textarea
      v-bind="$attrs"
      :value="modelValue"
      @focusin="(e) => moveUpLabel(e, slotProps.addFocusAnimation)"
      @focusout="(e) => moveDownLabel(e, slotProps.addFocusOutAnimation)"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </ANSBaseInput>
</template>

<script>
import ANSBaseInput from "@/components/Input/ANSBaseInput";

export default {
  name: "ANSTextArea",
  components: {ANSBaseInput},
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  methods: {
    moveUpLabel: (_, animation) => {
      animation();
    },
    moveDownLabel: (e, animation) => {
      if (e.target.value.trim() === '') {
        animation();
      }
    }
  },
}
</script>

<style scoped>
textarea{
  width: 100%;
  min-height: 150px;
  resize: vertical;
}
</style>