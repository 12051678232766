<template>
  <div ref="shootingStarContainerRef" class="shooting-star-container">
    <div ref="starHeadRef">
      <div ref="starTailRef"/>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";

export default {
  name: "ShootingStar",
  setup() {
    const animationDuration = 4000;

    const shootingStarContainerRef = ref(null);
    const starTailRef = ref(null);
    const starHeadRef = ref(null);

    const containerWidth = ref(0)
    const containerHeight = ref(0)
    const containerWidthCss = computed(() => `${containerWidth.value}px`);
    const containerHeightCss = computed(() => `${containerHeight.value}px`);

    const startPointTop = ref(0);
    const startPointLeft = ref(0)
    const startPointTopCss = computed(() => `${startPointTop.value}px`)
    const startPointLeftCss = computed(() => `${startPointLeft.value}px`)

    const angle = ref(0);
    const angleCss = computed(() => `rotate(${angle.value}rad)`)

    const tailStreakSize = ref(0);
    const tailStreakSizeCss = computed(()=> `${tailStreakSize.value}px`);

    const vwToPx = (vw) => {
      return vw * window.innerWidth / 100;
    }

    const minWidthTrajectory = vwToPx(10)
    const maxWidthTrajectory = vwToPx(40)

    function randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const initValues = () => {
      containerWidth.value = randomIntFromInterval(minWidthTrajectory, maxWidthTrajectory)
      containerHeight.value = Math.round(containerWidth.value * 0.2);

      startPointTop.value = Math.round(Math.random() * (window.innerHeight / 4))
      startPointLeft.value = Math.round(Math.random() * (window.innerWidth - containerWidth.value))

      angle.value = Math.atan(containerHeight.value / containerWidth.value)

      tailStreakSize.value = Math.round(containerWidth.value * 0.4)
    }

    const startStar = () => {
      starTailRef.value.classList.remove('tail');
      starHeadRef.value.classList.remove('head');
      const nextAnimationDelay = Math.floor(Math.random() * 4000) + animationDuration;
      initValues()
      setTimeout(() => {
        starTailRef.value.classList.add('tail');
        starHeadRef.value.classList.add('head');
        setTimeout(startStar, animationDuration);
      }, nextAnimationDelay)
    }

    onMounted(() => {
      startStar()
    })

    return {
      tailStreakSizeCss,
      angleCss,
      startPointTopCss,
      startPointLeftCss,
      containerWidthCss,
      containerHeightCss,
      shootingStarContainerRef: shootingStarContainerRef,
      starTailRef,
      starHeadRef
    }
  }
}
</script>

<style scoped>
@keyframes move {
  0% {
    top: 0;
    left: 0;
  }
  100% {
    top: 100%;
    left: 100%;
  }
}

@keyframes light {
  0% {
    box-shadow: 0 0 0 0 #fff,
    0 0 0 0 var(--accent-color);
  }

  30% {
    box-shadow: 0 0 60px 10px #fff,
    0 0 140px 60px var(--accent-color);
  }

  100% {
    box-shadow: 0 0 0 0 #fff,
    0 0 0 0 var(--accent-color);
  }
}

@keyframes tail-streak {
  0% {
    width: 0;
  }
  20% {
    width: v-bind(tailStreakSizeCss);
  }
  100% {
    width: 0;
  }
}

.head {
  width: 0;
  height: 0;
  background-color: white;
  position: relative;
  animation: light 4s linear, move 4s linear;
}

.tail {
  background-image: linear-gradient(to right, transparent, var(--primary-color-light));
  width: 10vw;
  height: 2px;
  position: absolute;
  right: 0;
  transform-origin: bottom right;
  transform: v-bind(angleCss);
  animation: tail-streak 4s linear;
}

.shooting-star-container {
  position: absolute;
  top: v-bind(startPointTopCss);
  left: v-bind(startPointLeftCss);
  width: v-bind(containerWidthCss);
  height: v-bind(containerHeightCss);
}
</style>
