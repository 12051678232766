<template>
  <div ref="menuTemplateRef" class="menu-template">
    <MenuIcon
      v-if="isMobile" :class="['menu-icon', isMenuOpen ? 'menu-icon-open' : 'menu-icon-close']"
      @click="onToggleMenu"
    />
    <div ref="menu" :class="['social-wrapper', { 'menu-hide': !isMenuOpen }]">
      <Lamp :position="position">
        <div class="menu-items-container">
          <div v-for="menuLink in menuLinks" :key="menuLink.name" @click="onToggleMenu">
            <div :ref="el => menuItemsRef.push(el)" :class="menuLink.classNames">
              <router-link :to="menuLink.link">
                {{ menuLink.name }}
              </router-link>
            </div>
          </div>
        </div>
      </Lamp>
    </div>
  </div>
</template>

<script>
import MenuIcon from "@/components/icons/MenuIcon";
import useMobileMenu from "@/hooks/useMobileMenu";
import useMobile from "@/hooks/useMobile";
import {onClickOutside} from '@vueuse/core';
import {ref} from "vue";
import Lamp from "../Lamp/Lamp";
import {MENU_POSITION} from "../Lamp/data";


export default {
  name: "Menu",
  components: {Lamp, MenuIcon},
  setup() {
    const position = MENU_POSITION.LEFT
    const menuTemplateRef = ref(null);
    const menuItemsRef = ref([]);
    const menuLinks = [
      {
        name: "Home",
        link: '/',
        classNames: 'menu-item',
      },
      {
        name: "Articles",
        link: 'articles',
        classNames: 'menu-item menu-item-articles',
      },
      {
        name: "Projects",
        link: 'projects',
        classNames: 'menu-item menu-item-projects',
      },
      {
        name: "Contact",
        link: 'contact',
        classNames: 'menu-item',
      },
    ]

    const showMenuItems = () => {
      const delayBetweenShow = 150;
      menuItemsRef.value.forEach((menuItem, index) => {
        setTimeout(() => {
          showMenuItem(menuItem);
        }, (index + 1) * delayBetweenShow);
      })
    }

    const hideMenuItems = () => {
      menuItemsRef.value.forEach(menuItem => {
        showMenuItem(menuItem);
        hideMenuItem(menuItem);
      })
    }

    const showMenuItem = (menuItem) => {
      menuItem.classList.add('show-element')
      menuItem.classList.remove('hide-element')
    }

    const hideMenuItem = (menuItem) => {
      menuItem.classList.add('hide-element')
      menuItem.classList.remove('show-element')
    }

    const fadeMenuItems = (isMenuOpen) => {
      if (isMenuOpen) {
        showMenuItems();
      } else {
        hideMenuItems();
      }
    }

    const {isMobile} = useMobile();
    const {
      toggleMenu,
      isMenuOpen
    } = useMobileMenu(menuTemplateRef, isMobile, fadeMenuItems, hideMenuItems, showMenuItems);

    onClickOutside(menuTemplateRef, () => {
      if (isMenuOpen.value && isMobile.value) {
        toggleMenu();
      }
    })


    const onToggleMenu = () => {
      if (isMobile.value) {
        toggleMenu();
      }
    }

    return {
      menuTemplateRef,
      menuLinks,
      menuItemsRef,
      position,
      onToggleMenu,
      isMenuOpen,
      isMobile
    }
  }
}


</script>

<style scoped>
@import './css/keyframes.css';
@import "./css/tablet.css";

.menu-template {
  --defaultLightPosY: 50%;
  --defaultLightPosX: -100px;
}

a {
  width: 100%;
  text-align: left;
  z-index: 10;
  color: var(--primary-color-light);
  padding-left: 30px;
  padding-right: 50px;
  height: 100px;
  line-height: 100px;
  text-decoration: none;
}

.social-wrapper {
  position: relative;
  display: flex;
  z-index: 5;
  /*height: fit-content;*/
  width: 100%;
  /*height: 100%;*/
}

.menu-items-container {
  position: relative;
}

.show-element {
  animation: showElement 500ms forwards;
}

.hide-element {
  animation: hideElement 500ms forwards;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: x-large;
}

.menu-item:hover > a {
  color: var(--accent-color-light);
}

.menu-item-articles,
.menu-item-projects {
  padding-left: 25px;
}

.menu-icon {
  width: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 10;
}

.menu-icon-open {
  animation: slideMenuIconOpen 500ms forwards;
}

.menu-icon-close {
  animation: slideMenuIconClose 500ms forwards;
}

.menu-hide {
  visibility: collapse;
}

.close-menu {
  animation: closeMenu 500ms forwards;
}

.open-menu {
  animation: openMenu 500ms forwards;
}


</style>