<template>
  <slot />
  <div class="container">
    <div
      v-for="notification in notifications" :key="notification.key" class="notification"
      :class="{
        'notification-success': notification.type=== 'SUCCESS',
        'notification-error': notification.type === 'ERROR'
      }"
    >
      <IconClose class="icon-close" @click="() => removeNotification(notification.key)" />
      <div v-if="notification.title" class="title">
        {{ notification.title.toUpperCase() }}
      </div>
      <div v-if="notification.message" class="message">
        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import IconClose from "@/components/icons/IconClose";

export default {
  name: "Notifications",
  components: {IconClose},
  setup() {
    const notifications = ref([])

    const removeNotification = (key) => {
      notifications.value = notifications.value.filter(notification => notification.key !== key);
    }

    const appendNotification = (newNotification) => {
      const notificationsWithoutSameKey = notifications.value.filter(notification => notification.key !== newNotification.key);

      notifications.value = [...notificationsWithoutSameKey, newNotification]
    }


    return {
      removeNotification,
      appendNotification,
      notifications
    }
  }
}
</script>

<style scoped>
.container {
  --color-success: var(--accent-color-dark);
  --bg-success: var(--accent-color-test);
  --color-error: #a32822;
  --bg-error: #eec8c5;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: var(--notification-z-index);
}

.notification {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  padding: 24px 48px 24px 24px;
  transition: 0.3s;
  border-radius: 15px;
  max-width: 250px;
  text-align: left;
}

.title {
  font-weight: bold;
  font-size: large;
}

.message {
  line-height: 22px;
}

.title + .message {
  margin-top: 12px;
}



.icon-close {
  position: absolute;
  right: 12px;
  top: 20px;
  width: 30px;
  cursor: pointer;
}

/* Notification styles */
.notification-success {
  background-color: var(--bg-success);
  border: 2px solid var(--color-success);
  color: var(--color-success);
}

.notification-success > .icon-close {
  fill: var(--color-success);
}

.notification-error {
  background-color: var(--bg-error);
  border: 3px solid var(--color-error);
  color: var(--color-error);
}

.notification-error > .icon-close {
  fill: var(--color-error);
}


</style>