import {ref} from "vue";

const useLabelInput = (animationNameFocusOn, animationNameFocusOut) => {
    const labelRef = ref(null);

    const addFocusAnimation = () => {
        labelRef.value.classList.remove(animationNameFocusOut);
        labelRef.value.classList.add(animationNameFocusOn)
    }

    const addFocusOutAnimation = () => {
        labelRef.value.classList.remove(animationNameFocusOn);
        labelRef.value.classList.add(animationNameFocusOut);
    }

    return {
        addFocusAnimation,
        addFocusOutAnimation,
        labelRef
    }
}

export default useLabelInput;