<template>
  <div ref="textRef" />
</template>

<script>
import {onBeforeUnmount, onMounted, ref} from "vue";

export default {
  name: "MatrixText",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const characters = "-+*/|}{[]~\\\":;?/.><=+-_)(*&^%$#@!)}";
    const textRef = ref(null);
    const timeouts = [];

    const getRandomChar = () => {
      return characters[Math.floor(Math.random() * characters.length)];
    }

    const showOriginalContent = (index) => {
      const {text} = props

      timeouts.push(
          setTimeout(() => {
            let newText = [...textRef.value.innerText]
            newText[index] = [...text][index]
            textRef.value.innerText = newText.join('')
          }, index * 80)
      )
    }

    onMounted(() => {
      const {text} = props
      textRef.value.innerText = [...text].map(char => char === " " ? " " : getRandomChar()).join('');
      [...text].forEach((value, index) => showOriginalContent(index));
    })

    onBeforeUnmount(()=>{
      timeouts.forEach(timeout => clearTimeout(timeout))
    })

    return {
      textRef
    }
  }
}
</script>
