import {createApp} from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/pages/Home";
import Contact from "@/pages/Contact";
import Articles from "@/pages/Articles";
import Projects from "@/pages/Projects";
import mitt from "mitt";

const routes = [
    {path: '/', component: Home},
    {path: '/articles', component: Articles},
    {path: '/projects', component: Projects},
    {path: '/contact', component: Contact}
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter

app.use(router)
    .mount('#app')
