<template>
  <button @click="callAction">
    <div class="button-content">
      <RingLoader class="loader" v-if="isCalling" />
      <slot />
    </div>
  </button>
</template>

<script>
import RingLoader from "@/components/Loaders/RingLoader";
import {ref} from "vue";

export default {
  name: "ANSButton",
  components: {RingLoader},
  props: {
    cta: {
      type: Function,
      default: () => {
      }
    }
  },
  emits: ['ctaSuccess', 'ctaError'],
  setup(props, {emit}) {
    const isCalling = ref(false);
    const callAction = async () => {
      const {cta} = props;
      isCalling.value = true;
      try {
        await cta();
        emit('ctaSuccess')
      } catch (e) {
        emit('ctaError', e)
      }
      isCalling.value = false;
    }
    return {
      callAction,
      isCalling
    }
  }
}
</script>

<style scoped>

button {
  border-radius: 8px;
  padding: 12px 28px;
  cursor: pointer;
  border: solid 2px var(--accent-color);
  background-color: transparent;
  color: var(--accent-color);
  font-size: large;
  transition: all ease 0.3s;
}

button:hover {
  background-color: var(--accent-color-light);
  border: solid 2px transparent;
  color: var(--primary-color)
}

.button-content {
  display: flex;
}

.loader {
  margin-right: 8px;
}
</style>