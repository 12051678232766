import {ref, watch} from "vue";

/**
 *
 * @param menuRef
 * @param isOnMobile Vue ref that indicates if view is mobile
 * @param onToggleCallback What to call when the menu is toggled
 * @param switchToMobileCallback Function called when resized to mobile
 * @param switchToDesktopCallback Function called when resized to desktop
 * @returns {{ isMenuOpen: ref<boolean>, toggleMenu: (function(): undefined)}}
 */
const useMobileMenu = (menuRef, isOnMobile, onToggleCallback, switchToMobileCallback, switchToDesktopCallback) => {
  const isMenuOpen = ref(!isOnMobile.value)

  watch(isOnMobile, (newValue) => {
    isMenuOpen.value = !isOnMobile.value;

    //Switch to mobile
    if (newValue) {
      closeMenu();
      if (switchToMobileCallback) {
        switchToMobileCallback();
      }
    } else { //Switch to desktop
      openMenu();
      if (switchToDesktopCallback) {
        switchToDesktopCallback();
      }
    }
  })

  const openMenu = () => {
    if (!menuRef.value)
      return;
    menuRef.value.classList.remove('close-menu')
    menuRef.value.classList.add('open-menu')
  }

  const closeMenu = () => {
    if (!menuRef.value)
      return;
    menuRef.value.classList.remove('open-menu')
    menuRef.value.classList.add('close-menu')
  }


  const toggleMenu = () => {
    if (!isOnMobile)
      return;

    isMenuOpen.value = !isMenuOpen.value;
    if (isMenuOpen.value) {
      openMenu();
    } else {
      closeMenu()
    }
    if (onToggleCallback)
      onToggleCallback(isMenuOpen.value);
  }

  return {
    isMenuOpen,
    toggleMenu
  }
}

export default useMobileMenu;
