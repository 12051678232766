<template>
  <div class="social-links-template">
    <div class="social-wrapper">
      <Lamp :position="position">
        <div class="social-items-container">
          <a href="https://twitter.com/andasilva_dev" target="_blank" class="social-menu-item">
            <Twitter/>
          </a>
          <a href="https://www.linkedin.com/in/andre-neto-da-silva-FullStack-D3v" target="blank"
             class="social-menu-item">
            <Linkedin class="linkedin"/>
          </a>
          <a href="https://github.com/andasilva" target="_blank" class="social-menu-item">
            <Github class="github"/>
          </a>
          <a href="https://www.instagram.com/andredasilva15/" target="_blank" class="social-menu-item">
            <Instagram class="instagram"/>
          </a>
          <a href="https://www.facebook.com/andre.dasilva.108/" target="_blank" class="social-menu-item">
            <Facebook class="facebook"/>
          </a>
        </div>
      </Lamp>
    </div>
  </div>
</template>

<script>
import Linkedin from "@/components/icons/Linkedin";
import Github from "@/components/icons/Github";
import Instagram from "@/components/icons/Instagram";
import Facebook from "@/components/icons/Facebook";
import Lamp from "./Lamp/Lamp";
import {onBeforeMount, onMounted, onUnmounted, ref} from "vue";
import {MENU_POSITION} from "./Lamp/data";
import Twitter from "@/components/icons/Twitter";

export default {
  name: "SocialLinks",
  components: {Twitter, Lamp, Facebook, Instagram, Github, Linkedin},
  setup() {
    const position = ref(MENU_POSITION.RIGHT)

    // const isMobile = window.innerWidth <= 1200;
    const changePositionOnResize = function () {
      if (window.innerWidth <= 1200) {
        position.value = MENU_POSITION.BOTTOM;
      } else {
        position.value = MENU_POSITION.RIGHT;
      }
    }

    onBeforeMount(() => {
      changePositionOnResize()
    })

    onMounted(() => {
      window.addEventListener('resize', changePositionOnResize);
    })
    onUnmounted(() => {
      window.removeEventListener('resize', changePositionOnResize)
    })


    return {position}
  },
}
</script>

<style scoped>
.social-wrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  --social-item-size: 150px;
  --social-item-size-tablet: 100px;
  --social-item-size-mobile: 50px;
}

svg {
  height: 60px;
  width: 60px;
  cursor: pointer;
  fill: var(--primary-color-light);
}

.social-items-container {
  position: relative;
}


.social-menu-item:hover > svg {
  fill: var(--accent-color) !important;
}

.social-menu-item {
  display: flex;
  position: relative;
  z-index: var(--social-panel-items-z-index);
  width: var(--social-item-size);
  justify-content: flex-end;
  cursor: pointer;
  font-size: x-large;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 28px;
}

.github {
  padding-right: 35px;
}

.linkedin,
.instagram {
  padding-right: 25px;
}

@media screen and (max-width: 1200px) {
  svg {
    height: 50px;
    width: 50px;
  }

  .social-items-container {
    display: flex;
  }

  .social-menu-item {
    height: 100px;
    display: flex;
    align-items: flex-end;
    width: var(--social-item-size-tablet);
    justify-content: center;
    padding-right: unset;
  }

  .github {
    padding-right: unset;
    padding-bottom: 35px;
  }

  .linkedin,
  .instagram {
    padding-right: unset;
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 640px) {
  svg {
    height: 30px;
    width: 30px;
  }

  .social-menu-item {
    height: 80px;
    width: var(--social-item-size-mobile);
  }
}

</style>

