<template>
  <div class="container">
    <MatrixText
      class="title"
      text="Get in touch!"
    />
    <form @submit.prevent="">
      <ANSInput v-model="form.from" label="E-mail" type="email" />
      <ANSInput v-model="form.subject" label="Subject" type="text" />
      <ANSTextArea v-model="form.message" label="Message" />
      <ANSButton type="submit" :cta="submit" @cta-success="handleSubmitSuccess" @cta-error="handleSubmitError">
        submit
      </ANSButton>
    </form>
  </div>
</template>

<script>
import ANSInput from "@/components/Input/ANSInput";
import ANSTextArea from "@/components/Input/ANSTextArea";
import ANSButton from "@/components/Button/ANSButton";
import MatrixText from "@/components/MatrixText";

export default {
  name: "Contact",
  components: {MatrixText, ANSButton, ANSTextArea, ANSInput},
  data() {
    return {
      form: {
        from: "",
        subject: "",
        message: ""
      }
    }
  },
  methods: {
    resetForm() {
      this.form = {
        from: '',
        subject: '',
        message: ''
      }
    },
    async submit() {
      const resp = await fetch("https://mail.andredasilva.dev/andredasilva", {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json'
        },
        body: JSON.stringify(this.form)
      })


      if(resp.ok){
        this.emitter.emit('notification', {
          key: 'contact',
          title: "Message envoyé!",
          message: "je vous répondrai dans les plus brefs délais!",
          type: "SUCCESS"
        })
      }else{
        this.emitter.emit('notification', {
          key: 'contact',
          title: "Oupss!",
          message: "Votre message n'as pas pu être envoyé! Veuillez réssayer plus tard ou écrivez moi sur contact@andredasilva.dev",
          type: "ERROR"
        })
      }
    },
    handleSubmitSuccess: function () {
      this.resetForm();
    },
    handleSubmitError: function(e) {
      this.emitter.emit('notification', {
        key: 'contact',
        title: "Oupss!",
        message: "Votre message n'as pas pu être envoyé! Veuillez réssayer plus tard ou écrivez moi à l'adresse contact@andredasilva.dev",
        type: "ERROR"
      })
      console.debug(e);
    },
  }
}
</script>

<style scoped>

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
  max-width: 1200px;
}

.title {
  font-size: xx-large;
  font-weight: bold;
  margin-top: 24px;
}


.input-container {
  height: 60px;
  position: relative;
}

.input-container + .input-container {
  margin-top: 20px;
}

form *:not(:first-child) {
  margin-top: 12px;
}


</style>
