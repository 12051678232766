<template>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "RingLoader",
  props: {
    size: {
      type: Number,
      default: 24
    }
  },
  setup(props){
    const ringSizeCss = computed(()=>{
      return `${props.size}px`
    })

    const marginCss = computed(()=>{
      return `${props.size / 10}px`
    })

    const ringInternalSizeCss = computed(()=>{
      return `${(props.size / 10 * 8)}px`
    })



    return {
      marginCss,
      ringSizeCss,
      ringInternalSizeCss
    }
  }
}
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: v-bind(ringSizeCss);
  height: v-bind(ringSizeCss);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: v-bind(ringInternalSizeCss);
  height: v-bind(ringInternalSizeCss);
  margin: v-bind(marginCss);
  border: v-bind(marginCss) solid var(--accent-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--accent-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>