<template>
  <div>
    <WIP class="wip"/>
  </div>
</template>

<script>
import WIP from "@/components/WIP";
export default {
  name: "Articles",
  components: {WIP}

}
</script>

<style scoped>
.wip{
  margin-top: 60px;
}
</style>
