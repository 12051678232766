<template>
  <div class="template-logo">
    <div id="logo-animated" />
  </div>
</template>

<script>
import {onMounted} from "vue";
import bodymovin from "lottie-web";

export default {
  name: "Logo",
  setup(){
    onMounted(()=>{
      bodymovin.loadAnimation({
        container: document.getElementById('logo-animated'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: 'data.json'
      })
    })
  }
}
</script>

<style scoped>
.template-logo{
  display: flex;
  justify-content: center;
  padding-top: 10vh;
}
#logo-animated{
  width: 30vw;
  max-width: 250px;
  height: fit-content;
}

</style>
