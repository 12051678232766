<template>
  <div class="ans-base-input-template">
    <label ref="labelRef">{{ label }}</label>
    <div ref="inputRef" class="input">
      <slot :addFocusAnimation="addFocusAnimation" :addFocusOutAnimation="addFocusOutAnimation"/>
    </div>
  </div>
</template>

<script>
import useLabelInput from "@/hooks/useLabelInput";
import {computed, onMounted, ref} from "vue";

export default {
  name: "ANSBaseInput",
  props: {
    label: {
      type: String,
      default: ''
    },
    labelHeight: {
      type: Number,
      default: 25
    }
  },
  setup() {
    const inputRef = ref();
    const inputHeightRef = ref(45);

    const labelHeight = 25;
    const labelHeightCSS = `${labelHeight}px`;
    const inputHeightCSS = `${inputHeightRef.value}px`;
    const labelMarginTopCSS = computed(()=>`${inputHeightRef.value/2}px`);

    onMounted(()=>{
      inputHeightRef.value = inputRef.value.getBoundingClientRect().height;
    })

    const {addFocusAnimation,addFocusOutAnimation,labelRef} = useLabelInput('label-animation-focus', 'label-animation-focus-out')
    return {inputRef, addFocusAnimation, addFocusOutAnimation,labelMarginTopCSS, labelHeightCSS, inputHeightCSS, labelRef}
  }
}
</script>

<style scoped>

.ans-base-input-template{
  position: relative;
}

.input{
  width: 100%;
  padding: v-bind(labelHeightCSS) 0 0 0;
}


label {
  margin-top: v-bind(labelMarginTopCSS);
  position: absolute;
  pointer-events: none;
  left: 5px;
  height: v-bind(labelHeightCSS);
  display: flex;
  align-items: center;
}

.label-animation-focus {
  animation: moveUpLabel 0.3s forwards;
}

.label-animation-focus-out {
  animation: moveDownLabel 0.3s forwards;
}


@keyframes moveUpLabel {
  0% {
    margin-top: v-bind(labelMarginTopCSS);
  }
  100% {
    margin-top: 0;
    font-weight: 900;
    font-size: large;
    color: var(--accent-color);
  }
}

@keyframes moveDownLabel {
  0% {
    margin-top: 0;
    font-weight: 900;
    font-size: large;
    color: var(--accent-color);
  }
  100% {
    margin-top: v-bind(labelMarginTopCSS);
  }
}
</style>