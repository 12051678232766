<template>
  <ANSBaseInput v-slot="slotProps" :label="label">
    <input
      v-bind="$attrs"
      :value="modelValue"
      @focusin="(e) => moveUpLabel(e, slotProps.addFocusAnimation)"
      @focusout="(e) => moveDownLabel(e, slotProps.addFocusOutAnimation)"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </ANSBaseInput>
</template>

<script>
import ANSBaseInput from "@/components/Input/ANSBaseInput";

export default {
  name: "ANSInput",
  components: {ANSBaseInput},
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue', 'input:focus', 'input:focusout'],
  methods: {
    moveUpLabel: (_, animation)=>{
      animation();
    },
    moveDownLabel: (e, animation) => {
      if(e.target.value.trim() === ''){
        animation();
      }
    }
  },
}
</script>

<style scoped>
input {
  border: 0;
  height: 45px;
  width: 100%;
}
</style>