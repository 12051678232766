<template>
  <div id="home">
    <MatrixText
      id="fullstack"
      text="Full Stack Developer"
    />
    <div class="description">
      <div>
        Passionate about programming since the age of 14 👶🏻, graduated with a master's degree in <br>
        <b>Information and Communication Technologies - Complex Information System</b>
        <br>
        I created this portofolio to group and share my projects/articles
        <br>
        <div class="contact">
          Feel free to contact me ✌️
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatrixText from "@/components/MatrixText";

export default {
  name: "Home",
  components: {
    MatrixText
  },
  data() {
    return {
      styles: {
        description: {
          maxWidth: '60vw',
          marginTop: '60px',
          fontSize: 'x-large',
          lineHeight: '1.5em',
        }
      },
      color: 'red',
      mobile: 1200
    }
  }
}
</script>

<style scoped>

#home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  max-width: 60vw;
  margin-top: 60px;
  font-size: x-large;
  line-height: 1.5em;
}

.contact {
  margin-top: 40px;
}

#fullstack {
  margin-top: 50px;
  font-size: xxx-large;
}

@media screen and (max-width: 1200px) {
  #fullstack {
    font-size: xx-large;
  }

  .description {
    max-width: 80vw;
    margin-top: 45px;
    font-size: larger;
    line-height: 1.5em;
  }

}


</style>
