<template>
  <Name id="name" />
  <ShootingStar />
  <ShootingStar />
  <ShootingStar />
  <Logo id="logo" />
  <Menu id="menu" />
  <SocialLinks id="social" />
  <Notifications ref="notificationRef" @notification="()=> console.log('Notification received!')">
    <div id="content">
      <router-view />
    </div>
  </Notifications>
</template>

<script>

import Menu from "@/components/Menu/Menu";
import Name from "@/components/Name";
import Logo from "@/components/Logo";
import SocialLinks from "@/components/SocialLinks";
import ShootingStar from "@/components/ShootingStar";
import Notifications from "@/components/Notifications";
import {onMounted, ref} from "vue";
import useEmitter from "@/hooks/useEmmiter.ts";

export default {
  name: 'App',
  components: {
    Notifications,
    ShootingStar,
    SocialLinks,
    Logo,
    Name,
    Menu
  },
  setup(){
    const notificationRef = ref()

    onMounted(function(){
      const emitter = useEmitter()
      emitter.on("notification", event => {
        notificationRef.value.appendNotification(event)
      })
    })

    return {
      notificationRef
    }
  }
}
</script>

<style>
@import "./assets/styles/global.css";

body,
html {
  margin: 0;
  padding: 0;
  --primary-color: #0d141b;
  --primary-color-light: #9ba8b3;
  --accent-color-dark: #46573e;
  --accent-color-test: #dcf3d7;
  --accent-color: #33b197;
  --accent-color-light: #8fd2c5;
  --media-tablet: 1200;
  --media-mobile: 640;
  /*--accent-color: #42b983;*/
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--primary-color);
  background-image: url('~@/assets/background.jpg');
  background-size: cover;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    ". logo name"
    "menu content social"
    "footer footer footer";

}

#logo {
  grid-area: logo;
}

#name {
  grid-area: name;
  margin: 15px 15px 0 0;
  text-align: end;
}

#menu {
  grid-area: menu;
  display: flex;
  align-items: flex-start;
}

#content {
  grid-area: content;
  color: #9b9b9b;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

#social {
  grid-area: social;
}

@media screen and (max-width: 1200px) {
  #app {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
    "name"
    "logo"
    "content"
    "social";
  }

  #menu {
    position: absolute;
    align-items: center;
    background-color: var(--primary-color);
    height: 100vh;
    z-index: var(--menu-index);
  }

  #social {
    display: flex;
    justify-content: center;
  }
}

.router-link-active {
  color: var(--accent-color) !important;
}


</style>
